import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useUser } from '@clerk/clerk-react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Badge } from './ui/badge';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from './ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Check, Edit2, Trash2, Plus, DollarSign, UserCircle2, ShieldCheck } from 'lucide-react';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: true
    },
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
);

const SuccessToast = ({ onComplete }) => {
  React.useEffect(() => {
    const timer = setTimeout(onComplete, 1000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="fixed top-5 left-1/2 -translate-x-1/2 z-50 px-6 py-3 rounded-lg bg-emerald-900/90 backdrop-blur-sm flex items-center gap-2 animate-in fade-in slide-in-from-top-full duration-200 animate-out fade-out duration-200 delay-800">
      <Check className="h-4 w-4 text-emerald-400" />
      <span className="text-white">Updated successfully</span>
    </div>
  );
};

// Add the gradient generator function
const getGradient = (str) => {
  const gradients = [
    'from-indigo-500 via-purple-500 to-pink-500',
    'from-rose-400 via-fuchsia-500 to-indigo-500',
    'from-blue-500 via-teal-500 to-emerald-500',
    'from-yellow-400 via-orange-500 to-pink-500',
    'from-green-400 via-cyan-500 to-blue-500',
    'from-purple-500 via-violet-500 to-indigo-500'
  ];
  
  // Generate a consistent index based on the string
  const index = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % gradients.length;
  return gradients[index];
};

const Members = ({ isAdmin, user }) => {
  const [members, setMembers] = useState([]);
  const [editingMember, setEditingMember] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    is_admin: false,
    hourly_rate: '',
  });

  // Add new state for whitelist
  const [whitelistEmails, setWhitelistEmails] = useState([]);
  const [newWhitelistEmail, setNewWhitelistEmail] = useState('');
  const [whitelistError, setWhitelistError] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  // Add state for active tab
  const [activeTab, setActiveTab] = useState('members');

  useEffect(() => {
    fetchMembers();
    fetchWhitelist();
  }, []);

  const fetchMembers = async () => {
    try {
      const { data, error } = await supabase
        .from('app_users')
        .select('*')
        .order('name');

      if (error) throw error;

      setMembers(data || []);
    } catch (error) {
      console.error('Error fetching members:', error);
      setError('Failed to load members');
    }
  };

  const fetchWhitelist = async () => {
    try {
      const { data, error } = await supabase
        .from('allowed_emails')
        .select('*')
        .order('email');

      if (error) throw error;
      setWhitelistEmails(data || []);
    } catch (error) {
      console.error('Error fetching whitelist:', error);
      setWhitelistError('Failed to load whitelist');
    }
  };

  const handleEditClick = (member) => {
    console.log('Editing member:', member);
    setEditingMember(member);
    setFormData({
      is_admin: member.is_admin,
      hourly_rate: member.hourly_rate || ''
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingMember(null);
    setFormData({ is_admin: false, hourly_rate: '' });
  };

  const handleUpdateMember = async () => {
    try {
      if (!editingMember?.id) {
        throw new Error('Invalid member ID');
      }

      // Ensure the data types are correct
      const hourlyRate = formData.hourly_rate ? parseFloat(formData.hourly_rate) : null;
      const isAdmin = formData.is_admin === true;

      console.log('Attempting update with:', {
        id: editingMember.id,
        hourly_rate: hourlyRate,
        is_admin: isAdmin
      });

      // Perform the update
      const { error } = await supabase
        .from('app_users')
        .update({
          hourly_rate: hourlyRate,
          is_admin: isAdmin
        })
        .eq('id', editingMember.id);

      if (error) {
        console.error('Update error:', error);
        throw error;
      }

      // Fetch the updated record to verify
      const { data: updatedMember, error: fetchError } = await supabase
        .from('app_users')
        .select('*')
        .eq('id', editingMember.id)
        .single();

      if (fetchError) {
        console.error('Fetch error:', fetchError);
        throw fetchError;
      }

      console.log('Updated member data:', updatedMember);

      // Update local state
      setMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === editingMember.id ? updatedMember : member
        )
      );

      handleCloseDialog();
      setShowSuccess(true);

    } catch (error) {
      console.error('Error in handleUpdateMember:', error);
      setError('Failed to update member: ' + error.message);
    }
  };

  const handleDeleteMember = async (memberId) => {
    if (!window.confirm('Are you sure you want to remove this member?')) return;

    try {
      const { error } = await supabase
        .from('app_users')
        .delete()
        .eq('id', memberId);

      if (error) throw error;

      setShowSuccess(true);
      fetchMembers();
    } catch (error) {
      console.error('Error deleting member:', error);
      setError('Failed to remove member');
    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewWhitelistEmail(email);
    if (attemptedSubmit) {
      setEmailError(validateEmail(email) ? '' : 'Please enter a valid email address');
    }
  };

  const addToWhitelist = async (e) => {
    e.preventDefault();
    setAttemptedSubmit(true);
    
    if (!newWhitelistEmail || !validateEmail(newWhitelistEmail)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      const currentUserEmail = user.emailAddresses[0].emailAddress;
      
      const { error } = await supabase
        .from('allowed_emails')
        .insert([{ 
          email: newWhitelistEmail.toLowerCase(),
          added_by: currentUserEmail
        }]);

      if (error) throw error;

      setNewWhitelistEmail('');
      setEmailError('');
      setAttemptedSubmit(false);
      setWhitelistError(null);
      setShowSuccess(true);
      fetchWhitelist();
    } catch (error) {
      console.error('Error adding to whitelist:', error);
      setWhitelistError('Failed to add email to whitelist');
    }
  };

  const removeFromWhitelist = async (id) => {
    try {
      const { error } = await supabase
        .from('allowed_emails')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setShowSuccess(true);
      fetchWhitelist();
    } catch (error) {
      console.error('Error removing from whitelist:', error);
      setWhitelistError('Failed to remove email from whitelist');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Team Management</h2>
          <p className="text-muted-foreground">
            Manage team members and access control
          </p>
        </div>
      </div>

      {showSuccess && (
        <SuccessToast onComplete={() => setShowSuccess(false)} />
      )}

      {error && (
        <Alert variant="destructive" onClose={() => setError(null)}>
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="members">Team Members</TabsTrigger>
          <TabsTrigger value="whitelist">Whitelist</TabsTrigger>
        </TabsList>

        <TabsContent value="members">
          <Card>
            <CardContent className="p-0">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Member</TableHead>
                    <TableHead>Role</TableHead>
                    {isAdmin && <TableHead>Rate</TableHead>}
                    {isAdmin && <TableHead className="text-right">Actions</TableHead>}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {members.map((member) => (
                    <TableRow key={member.id}>
                      <TableCell>
                        <div className="flex items-center gap-4">
                          <Avatar>
                            <AvatarFallback className={`bg-gradient-to-br ${getGradient(member.email)} text-white`}>
                              {member.name?.[0] || member.email?.[0] || '?'}
                            </AvatarFallback>
                          </Avatar>
                          <div>
                            <p className="font-medium">{member.name}</p>
                            <p className="text-sm text-muted-foreground">
                              {member.email}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Badge
                          variant={member.is_admin ? 'destructive' : 'secondary'}
                          className="gap-1"
                        >
                          {member.is_admin ? <ShieldCheck className="h-3 w-3" /> : <UserCircle2 className="h-3 w-3" />}
                          {member.is_admin ? 'Admin' : 'Member'}
                        </Badge>
                      </TableCell>
                      {isAdmin && (
                        <TableCell>
                          {member.hourly_rate ? (
                            <span className="text-emerald-400">
                              ${member.hourly_rate}/hr
                            </span>
                          ) : (
                            <span className="text-muted-foreground">
                              No rate set
                            </span>
                          )}
                        </TableCell>
                      )}
                      {isAdmin && (
                        <TableCell className="text-right">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-8 w-8"
                                  onClick={() => handleEditClick(member)}
                                >
                                  <Edit2 className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Edit Member</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-8 w-8 text-destructive"
                                  onClick={() => handleDeleteMember(member.id)}
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Remove Member</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="whitelist">
          <Card>
            <CardContent>
              <form onSubmit={addToWhitelist} className="mb-6">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <Input
                      type="email"
                      placeholder="Enter email address"
                      value={newWhitelistEmail}
                      onChange={handleEmailChange}
                      error={!!emailError && attemptedSubmit}
                    />
                    {attemptedSubmit && emailError && (
                      <p className="text-sm text-destructive mt-1">{emailError}</p>
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="gap-2"
                    disabled={!newWhitelistEmail || !!emailError}
                  >
                    <Plus className="h-4 w-4" />
                    Add Email
                  </Button>
                </div>
              </form>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Email Address</TableHead>
                    <TableHead className="text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {whitelistEmails.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.email}</TableCell>
                      <TableCell className="text-right">
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 text-destructive"
                          onClick={() => removeFromWhitelist(item.id)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      {isAdmin && (
        <Dialog open={openDialog} onOpenChange={setOpenDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Member: {editingMember?.name}</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid gap-2">
                <label className="text-sm font-medium">Role</label>
                <Select
                  value={formData.is_admin ? 'admin' : 'member'}
                  onValueChange={(value) => setFormData({ ...formData, is_admin: value === 'admin' })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="member">Member</SelectItem>
                    <SelectItem value="admin">Admin</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="grid gap-2">
                <label className="text-sm font-medium">Hourly Rate</label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                  <Input
                    type="number"
                    value={formData.hourly_rate}
                    onChange={(e) => setFormData({ ...formData, hourly_rate: e.target.value })}
                    className="pl-8"
                  />
                </div>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button onClick={handleUpdateMember}>
                Save Changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Members; 