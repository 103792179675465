"use client";

import * as React from "react";
import { format, startOfWeek, endOfWeek, addDays, eachDayOfInterval, startOfMonth, endOfMonth, isSameDay } from "date-fns";
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

function Calendar({
  mode = "single",
  selected,
  onSelect,
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) {
  const [month, setMonth] = React.useState(new Date());
  const [hoveredDate, setHoveredDate] = React.useState(null);

  // Get calendar grid for current month
  const getDaysInMonth = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    const firstWeekStart = startOfWeek(start, { weekStartsOn: 1 });
    const lastWeekEnd = endOfWeek(end, { weekStartsOn: 1 });
    
    return eachDayOfInterval({ start: firstWeekStart, end: lastWeekEnd });
  };

  // Split days into weeks
  const weeks = React.useMemo(() => {
    const days = getDaysInMonth(month);
    const weeks = [];
    let currentWeek = [];

    days.forEach((day) => {
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
      currentWeek.push(day);
    });

    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    return weeks;
  }, [month]);

  // Check if a date is in the selected week
  const isInSelectedWeek = (date) => {
    if (!selected || !date) return false;
    const weekStart = startOfWeek(selected, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(selected, { weekStartsOn: 1 });
    return date >= weekStart && date <= weekEnd;
  };

  // Check if a date is in the hovered week
  const isInHoveredWeek = (date) => {
    if (!hoveredDate || !date || isInSelectedWeek(date)) return false;
    const weekStart = startOfWeek(hoveredDate, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(hoveredDate, { weekStartsOn: 1 });
    return date >= weekStart && date <= weekEnd;
  };

  return (
    <div className={cn("p-3", className)}>
      <div className="flex justify-center pt-1 relative items-center">
        <Button
          variant="outline"
          className={cn(
            "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1"
          )}
          onClick={() => setMonth(addDays(month, -30))}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="text-sm font-medium">
          {format(month, "MMMM yyyy")}
        </div>
        <Button
          variant="outline"
          className={cn(
            "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-1"
          )}
          onClick={() => setMonth(addDays(month, 30))}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="mt-4">
        <div className="grid grid-cols-7 gap-1">
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
            <div
              key={day}
              className="text-muted-foreground text-center text-xs font-medium"
            >
              {day}
            </div>
          ))}
        </div>
        <div className="mt-2 grid grid-cols-7 gap-1">
          {weeks.map((week, weekIndex) =>
            week.map((day, dayIndex) => {
              const isHovered = isInHoveredWeek(day);
              const isInWeekSelection = isInSelectedWeek(day);
              const isOutsideMonth = month.getMonth() !== day.getMonth();

              return (
                <div
                  key={day.toString()}
                  onMouseEnter={() => setHoveredDate(day)}
                  onMouseLeave={() => setHoveredDate(null)}
                  className={cn(
                    "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 transition-colors",
                    isHovered && "bg-accent/30",
                    isInWeekSelection && "bg-accent",
                    isOutsideMonth && "text-muted-foreground opacity-50"
                  )}
                >
                  <button
                    type="button"
                    className={cn(
                      "h-8 w-8 p-0 font-normal",
                      "hover:bg-transparent",
                      "focus:outline-none",
                      isInWeekSelection && "text-accent-foreground"
                    )}
                    onClick={() => onSelect?.(day)}
                    disabled={props.disabled?.(day)}
                  >
                    {format(day, "d")}
                  </button>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export function DateRangePicker({
  className,
  value,
  onChange,
}) {
  const handleSelect = (day) => {
    if (!day) {
      onChange(undefined);
      return;
    }

    const weekStart = startOfWeek(day, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(day, { weekStartsOn: 1 });
    onChange({ from: weekStart, to: weekEnd });
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[260px] justify-start text-left font-normal",
              !value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value?.from ? (
              value.to ? (
                <>
                  {format(value.from, "LLL dd, y")} -{" "}
                  {format(value.to, "LLL dd, y")}
                </>
              ) : (
                format(value.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            mode="single"
            selected={value?.from}
            onSelect={handleSelect}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
} 