import React, { useEffect, useState } from 'react';
import { ClerkProvider, SignIn, SignedIn, SignedOut, useUser, useClerk } from '@clerk/clerk-react';
import TimeTracker from './components/TimeTracker';
import { createClient } from '@supabase/supabase-js';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Toaster } from 'sonner';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error('Missing Publishable Key');
}

async function checkAuthorization(email) {
  try {
    // Check if user exists in app_users
    const { data: existingUser, error: userError } = await supabase
      .from('app_users')
      .select('email')
      .eq('email', email)
      .single();

    if (userError && userError.code !== 'PGRST116') {
      throw userError;
    }

    // If user exists in app_users, they're authorized
    if (existingUser) {
      return true;
    }

    // Otherwise, check whitelist
    const { data: whitelistData, error: whitelistError } = await supabase
      .from('allowed_emails')
      .select('email')
      .eq('email', email)
      .single();

    if (whitelistError && whitelistError.code !== 'PGRST116') {
      throw whitelistError;
    }

    return !!whitelistData;
  } catch (error) {
    console.error('Error checking authorization:', error);
    return false;
  }
}

const AuthWrapper = ({ children }) => {
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (!user) return;

      const email = user.emailAddresses[0].emailAddress;
      const isUserAuthorized = await checkAuthorization(email);
      
      setIsAuthorized(isUserAuthorized);
      setChecking(false);
    };

    if (isLoaded && user) {
      checkAuth();
    }
  }, [user, isLoaded]);

  if (!isLoaded || checking) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!isAuthorized && user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4 space-y-4">
        <Alert variant="destructive">
          <AlertTitle>Access Denied</AlertTitle>
          <AlertDescription>
            Your email is not authorized to access this application.
          </AlertDescription>
        </Alert>
        <Button
          variant="default"
          onClick={() => {
            signOut();
            window.location.href = '/';
          }}
        >
          Return to Sign In
        </Button>
      </div>
    );
  }

  return children;
};

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <div className="app-container">
        <SignedIn>
          <AuthWrapper>
            <TimeTracker />
          </AuthWrapper>
        </SignedIn>
        <SignedOut>
          <div className="container mx-auto py-8 text-center">
            <SignIn />
          </div>
        </SignedOut>
        <Toaster />
      </div>
    </ClerkProvider>
  );
}

export default App; 