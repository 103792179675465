import React, { memo, useState, useEffect } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from './ui/card';
import { format, isSameDay, eachDayOfInterval } from 'date-fns';
import { DollarSign, Check, X } from 'lucide-react';
import { cn } from '../lib/utils';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const processChartData = (entries, dateRange, projectKey, projectRate, projectUsers) => {
  if (!dateRange[0] || !dateRange[1] || !entries?.length) return [];

  const interval = eachDayOfInterval({
    start: dateRange[0],
    end: dateRange[1]
  });

  return interval.map(date => {
    const dayEntries = entries.filter(entry => 
      entry.jira_project_key === projectKey && 
      isSameDay(new Date(entry.date), date)
    );

    const totalHours = dayEntries.reduce((sum, entry) => 
      sum + (entry.duration / (1000 * 60 * 60)), 0
    );

    const revenue = projectRate ? totalHours * projectRate : 0;

    return {
      date: format(date, 'MMM d'),
      total: Number(totalHours.toFixed(1)),
      revenue: Number(revenue.toFixed(2))
    };
  });
};

const CustomTooltip = ({ active, payload, isAdmin }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg border bg-card p-2 shadow-sm">
        <div className="grid gap-1">
          <div className="font-medium">{payload[0].payload.date}</div>
          <div className="text-sm text-muted-foreground">
            {payload[0].value.toFixed(1)} hours
          </div>
          {isAdmin && payload[1] && (
            <div className="text-sm text-emerald-500">
              ${payload[1].value.toFixed(2)}
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

const ProjectChart = memo(({ 
  project, 
  entries, 
  dateRange, 
  projectRate, 
  projectUsers, 
  isAdmin,
  onRateChange 
}) => {
  const [chartData, setChartData] = useState([]);
  const [isEditingRate, setIsEditingRate] = useState(false);
  const [editedRate, setEditedRate] = useState(projectRate?.toString() || '');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const data = processChartData(
      entries,
      dateRange,
      project.key,
      projectRate,
      projectUsers
    );
    setChartData(data);
  }, [project.key, entries, dateRange, projectRate, projectUsers]);

  const totalHours = chartData.reduce((sum, day) => sum + day.total, 0);
  const totalRevenue = chartData.reduce((sum, day) => sum + day.revenue, 0);

  const handleSaveRate = async () => {
    const newRate = parseFloat(editedRate);
    if (isNaN(newRate)) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('jira_projects')
        .update({ hourly_rate: newRate })
        .eq('key', project.key);

      if (error) throw error;

      onRateChange(project.key, newRate);
      setIsEditingRate(false);
    } catch (error) {
      console.error('Error updating project rate:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-4">
              <CardTitle>{project.name}</CardTitle>
              {isAdmin && (
                <div className="flex items-center gap-2">
                  {isEditingRate ? (
                    <>
                      <div className="relative">
                        <DollarSign className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <input
                          type="number"
                          value={editedRate}
                          onChange={(e) => setEditedRate(e.target.value)}
                          className="w-24 pl-8 h-9 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                          placeholder="0.00"
                          step="0.01"
                          min="0"
                          disabled={saving}
                        />
                      </div>
                      <button
                        onClick={handleSaveRate}
                        disabled={saving}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <Check className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => {
                          setIsEditingRate(false);
                          setEditedRate(projectRate?.toString() || '');
                        }}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setIsEditingRate(true)}
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring px-3 py-1 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                    >
                      {projectRate ? `$${projectRate}/hr` : 'Set Rate'}
                    </button>
                  )}
                </div>
              )}
            </div>
            <CardDescription className="mt-1.5">
              <div className="flex items-center gap-4">
                <span>{totalHours.toFixed(1)} hours this period</span>
                {isAdmin && projectRate > 0 && (
                  <span className="text-emerald-500">
                    ${totalRevenue.toFixed(2)} revenue
                  </span>
                )}
              </div>
            </CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="h-[250px] w-full">
          <ResponsiveContainer>
            <LineChart data={chartData}>
              <CartesianGrid 
                strokeDasharray="3 3" 
                className="stroke-border" 
                vertical={false}
              />
              <XAxis 
                dataKey="date"
                className="text-xs text-muted-foreground"
                tickLine={false}
                axisLine={false}
                padding={{ left: 10, right: 10 }}
              />
              <YAxis 
                className="text-xs text-muted-foreground"
                tickLine={false}
                axisLine={false}
                width={40}
              />
              <Tooltip content={(props) => <CustomTooltip {...props} isAdmin={isAdmin} />} />
              <Line
                type="monotone"
                dataKey="total"
                className="stroke-primary"
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4, className: "fill-primary stroke-background stroke-2" }}
              />
              {isAdmin && projectRate > 0 && (
                <Line
                  type="monotone"
                  dataKey="revenue"
                  className="stroke-emerald-500"
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 4, className: "fill-emerald-500 stroke-background stroke-2" }}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
});

ProjectChart.displayName = "ProjectChart";

export default ProjectChart; 