import React, { useState } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isToday, formatDistanceToNow } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import { Badge } from './ui/badge';
import { DateRangePicker } from './ui/date-range-picker';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import { Check, Copy, Trash2, Clock } from 'lucide-react';

const formatEntriesForCopy = (entries) => {
  return entries
    .map(entry => {
      const date = format(new Date(entry.date), 'MMMM d, yyyy');
      const duration = `${Math.floor(entry.duration / (1000 * 60 * 60))}h ${Math.floor((entry.duration % (1000 * 60 * 60)) / (1000 * 60))}m`;
      return `${date}\n${entry.jira_project_name} - ${entry.task}\n${entry.task_description || ''}\nDuration: ${duration}\n`;
    })
    .join('\n');
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy text:', err);
  }
};

const groupDataByUser = (entries) => {
  const userMap = new Map();

  entries.forEach(entry => {
    if (!userMap.has(entry.user_email)) {
      userMap.set(entry.user_email, {
        email: entry.user_email,
        name: entry.user_name,
        entries: [],
        lastLogged: null
      });
    }
    const userData = userMap.get(entry.user_email);
    userData.entries.push(entry);
    
    // Update last logged time
    const entryDate = new Date(entry.created_at);
    if (!userData.lastLogged || entryDate > userData.lastLogged) {
      userData.lastLogged = entryDate;
    }
  });

  return Array.from(userMap.values())
    .sort((a, b) => a.name.localeCompare(b.name));
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg border bg-card p-2 shadow-sm">
        <div className="font-medium">{payload[0].payload.date}</div>
        <div className="text-sm text-muted-foreground">
          {payload[0].value.toFixed(1)} hours
        </div>
      </div>
    );
  }
  return null;
};

const CopyButton = ({ onCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    await onCopy();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className={isCopied ? 'text-emerald-400' : 'text-muted-foreground'}
            onClick={handleCopy}
          >
            {isCopied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Copy all descriptions</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

// Add the gradient generator function
const getGradient = (str) => {
  const gradients = [
    'from-indigo-500 via-purple-500 to-pink-500',
    'from-rose-400 via-fuchsia-500 to-indigo-500',
    'from-blue-500 via-teal-500 to-emerald-500',
    'from-yellow-400 via-orange-500 to-pink-500',
    'from-green-400 via-cyan-500 to-blue-500',
    'from-purple-500 via-violet-500 to-indigo-500'
  ];
  
  // Generate a consistent index based on the string
  const index = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % gradients.length;
  return gradients[index];
};

const UserReports = ({ 
  entries,
  dateRange,
  setDateRange,
  user,
  signOut,
  isAdmin,
  loading,
  error,
  UserMenu,
  formatDuration,
  deleteEntry,
}) => {
  // Initialize with current week (Monday-Sunday)
  React.useEffect(() => {
    const now = new Date();
    // Get current week's Monday
    const monday = startOfWeek(now, { weekStartsOn: 1 });
    // Get current week's Sunday
    const sunday = endOfWeek(now, { weekStartsOn: 1 });
    setDateRange([monday, sunday]);
  }, []);

  const getWeekDays = () => {
    const now = new Date();
    // If no date range, use current week
    if (!dateRange[0] || !dateRange[1]) {
      const start = startOfWeek(now, { weekStartsOn: 1 });
      const end = endOfWeek(start, { weekStartsOn: 1 });
      return eachDayOfInterval({ start, end });
    }
    
    // Get the Monday-Sunday week containing the selected date
    const start = startOfWeek(dateRange[0], { weekStartsOn: 1 });
    const end = endOfWeek(start, { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  // Group entries by user
  const userGroups = groupDataByUser(entries);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between sticky top-0 bg-background z-10 pb-6">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Team Reports</h2>
          <p className="text-muted-foreground">
            View and analyze team time tracking data
          </p>
        </div>
        <div className="flex items-center gap-4">
          <DateRangePicker
            value={{
              from: dateRange[0],
              to: dateRange[1]
            }}
            onChange={(range) => {
              if (range?.from) {
                const weekStart = startOfWeek(range.from, { weekStartsOn: 1 });
                const weekEnd = endOfWeek(range.from, { weekStartsOn: 1 });
                setDateRange([weekStart, weekEnd]);
              } else {
                setDateRange([null, null]);
              }
            }}
          />
        </div>
      </div>

      {userGroups.map((userData) => {
        // Calculate user-specific chart data
        const userChartData = (() => {
          if (!dateRange[0] || !dateRange[1]) return [];

          const interval = eachDayOfInterval({
            start: dateRange[0],
            end: dateRange[1]
          });

          return interval.map(date => {
            const userEntries = userData.entries.filter(entry => {
              const entryDate = new Date(entry.date);
              return format(entryDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
            });

            const totalHours = userEntries.reduce((sum, entry) => 
              sum + (entry.duration / (1000 * 60 * 60)), 0
            );

            return {
              date: format(date, 'MMM d'),
              total: Number(totalHours.toFixed(1))
            };
          });
        })();

        return (
          <Card key={userData.email} className="mb-6">
            <CardHeader className="flex flex-row items-center justify-between">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarFallback className={`bg-gradient-to-br ${getGradient(userData.email)} text-white`}>
                    {userData.name?.[0] || userData.email?.[0] || '?'}
                  </AvatarFallback>
                </Avatar>
                <div>
                  <CardTitle>{userData.name}</CardTitle>
                  <div className="flex items-center gap-2">
                    <p className="text-sm text-muted-foreground">
                      {userChartData.reduce((sum, day) => sum + day.total, 0).toFixed(1)} hours total
                    </p>
                    {userData.lastLogged && (
                      <>
                        <span className="text-muted-foreground">•</span>
                        <p className="text-sm text-muted-foreground">
                          Last logged {formatDistanceToNow(userData.lastLogged, { addSuffix: true })}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <CopyButton 
                onCopy={() => copyToClipboard(formatEntriesForCopy(userData.entries))}
              />
            </CardHeader>
            <CardContent>
              <div className="h-[300px] mb-6">
                <ResponsiveContainer>
                  <LineChart 
                    data={userChartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" className="stroke-border" />
                    <XAxis 
                      dataKey="date"
                      className="text-muted-foreground"
                    />
                    <YAxis 
                      className="text-muted-foreground"
                    />
                    <RechartsTooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="total"
                      stroke="hsl(var(--primary))"
                      strokeWidth={2}
                      dot={{ r: 3 }}
                      activeDot={{ r: 5 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {getWeekDays().map(date => {
                  const dateStr = format(date, 'yyyy-MM-dd');
                  const dayEntries = userData.entries.filter(entry => 
                    format(new Date(entry.date), 'yyyy-MM-dd') === dateStr
                  );

                  return (
                    <Card key={dateStr} className={isToday(date) ? 'border-primary bg-primary/5' : undefined}>
                      <CardHeader>
                        <CardTitle className="text-lg">
                          {format(date, 'EEEE')}
                        </CardTitle>
                        <p className="text-sm text-muted-foreground">
                          {format(date, 'MMMM d')}
                        </p>
                      </CardHeader>
                      <CardContent>
                        <ScrollArea className="h-[calc(100vh-24rem)] pr-4">
                          {dayEntries.length > 0 ? (
                            dayEntries.map(entry => (
                              <div
                                key={entry.id}
                                className="relative mb-4 rounded-lg border bg-card p-4 group"
                              >
                                <p className="text-xs text-muted-foreground mb-1">
                                  {entry.jira_project_name}
                                </p>
                                <p className="font-medium mb-1">
                                  {entry.task}
                                </p>
                                {entry.task_description && (
                                  <p className="text-sm text-muted-foreground mb-2 whitespace-pre-wrap break-words">
                                    {entry.task_description}
                                  </p>
                                )}
                                <div className="flex items-center gap-1 text-xs text-muted-foreground">
                                  <Clock className="h-3 w-3" />
                                  {formatDuration(entry.duration)}
                                </div>
                                <div className="absolute right-2 top-2 flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-8 w-8 text-destructive"
                                    onClick={() => deleteEntry(entry.id)}
                                  >
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p className="text-center text-sm text-muted-foreground py-4">
                              No entries for this day
                            </p>
                          )}
                        </ScrollArea>
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default UserReports; 