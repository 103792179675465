import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { DollarSign } from 'lucide-react';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const ProjectRates = ({ projects, projectRates, onRateChange }) => {
  const [editingRates, setEditingRates] = useState({});
  const [saving, setSaving] = useState(false);

  const handleRateChange = (projectKey, value) => {
    setEditingRates(prev => ({
      ...prev,
      [projectKey]: value
    }));
  };

  const handleSave = async (projectKey) => {
    const newRate = parseFloat(editingRates[projectKey]);
    if (isNaN(newRate)) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('jira_projects')
        .update({ hourly_rate: newRate })
        .eq('key', projectKey);

      if (error) throw error;

      onRateChange(projectKey, newRate);
      setEditingRates(prev => {
        const newRates = { ...prev };
        delete newRates[projectKey];
        return newRates;
      });
    } catch (error) {
      console.error('Error updating project rate:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardContent className="p-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Project Rates</h3>
          <div className="grid gap-4">
            {projects.map((project) => (
              <div key={project.key} className="flex items-center justify-between gap-4 p-2 rounded-lg hover:bg-muted/50">
                <div>
                  <p className="font-medium">{project.name}</p>
                  <p className="text-sm text-muted-foreground">{project.key}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="number"
                      value={editingRates[project.key] ?? projectRates[project.key] ?? ''}
                      onChange={(e) => handleRateChange(project.key, e.target.value)}
                      className="pl-8 w-32"
                      placeholder="0.00"
                      step="0.01"
                      min="0"
                    />
                  </div>
                  {editingRates[project.key] !== undefined && (
                    <Button
                      size="sm"
                      onClick={() => handleSave(project.key)}
                      disabled={saving}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectRates; 